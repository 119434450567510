<div class="container-fluid h-100" style="position: relative; z-index: 10">
  <app-header
    [navLayout]="setNavLayout"
    [defaultNavbar]="setDefaultNavbar"
    [toggleNavbar]="setToggleNavbar"
    [toggleStatus]="setToggleStatus"
    [navbarEffect]="setVerticalNavbarEffect"
    [headerColorTheme]="setHeaderColorTheme"
    [navbarColorTheme]="setNavbarColorTheme"
    [activeNavColorTheme]="setActiveNavColorTheme"
    [headerHeight]="setHeaderHeight"
    [leftHeaderColorTheme]="setLeftHeaderColorTheme"
    [collapsedLeftHeader]="setCollapsedLeftHeader"
    [deviceType]="setDeviceType"
    [ThemeStatus]="setThemeStatus"
  ></app-header>
  <div
    class="container d-flex w-100 p-3 justify-content-center mx-auto flex-column"
    style="height: calc(100% - 250px)"
  >
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mx-auto">
      <form class="px-3 tra" [formGroup]="loginForm" autocomplete="off">
        <mat-form-field class="w-100 mb-3">
          <mat-label>User ID</mat-label>
          <input
            matInput
            value
            type="text"
            formControlName="userName"
            placeholder="Enter User ID"
            autocomplete="off"
            name="fakeusername"
            required
          />
        </mat-form-field>

        <mat-form-field class="w-100 mb-3">
          <mat-label>Password</mat-label>
          <input
            matInput
            value
            type="text"
            id="password"
            name="fakepassowrd"
            formControlName="password"
            placeholder="Enter password"
            autocomplete="off"
            (input)="changeType()"
            (change)="changeType()"
            required
          />
        </mat-form-field>
        <mat-form-field class="w-100 mb-3">
          <mat-label>Secret Key</mat-label>
          <input
            matInput
            value
            type="text"
            autocomplete="off"
            formControlName="secret_key"
            placeholder="Enter Secret Key"
            name="secret_key"
            required
          />
        </mat-form-field>
        <mat-form-field class="w-100 mb-3">
          <mat-label>User Key</mat-label>
          <input
            matInput
            value
            type="text"
            autocomplete="off"
            name="fakeuserkey"
            formControlName="user_key"
            placeholder="Enter User Key"
            name="user_key"
            required
          />
        </mat-form-field>

        <div class="d-flex align-content-center" style="align-items: center">
          <button
            type="submit"
            class="active mr-auto"
            mat-btn
            (click)="Login()"
            [disabled]="!loginForm.valid"
          >
            <ngx-bootstrap-spinner
              name="loginLoading"
              bdColor="rgba(0, 0, 0, 0.8)"
              size="small"
              color="#d91d1d"
              type="border"
              isButtonSpinner="true"
              autoDisableButton="true"
              loadingText=""
            >
            </ngx-bootstrap-spinner>
            Submit
          </button>
        </div>
        <br />
        <div class="alert alert-danger" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </form>
    </div>
  </div>
  <footer>
    <div class="lfoot">
      <div class="lfd wb">
        Screening Implementation for Munsalik by LFD<br />
        Copyright © {{ copyRightYear }} LFD Private Limited. All Rights Reserved
      </div>
    </div>
  </footer>
</div>
